/* eslint-disable prettier/prettier */
/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */
import React from 'react';
import { decorate, computed, runInAction, observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import c from 'classnames';
import Header from '@amzn/base-ui/dist/parts/helpers/fields/Header';
import Description from '@amzn/base-ui/dist/parts/helpers/fields/Description';
import _ from 'lodash';

import SelectConfigurationCards from './SelectConfigurationCards';
import { allAvailableCategories } from './EnvTypeAdditionalInfo';

// expected props
// - configurations (via props) and array of the env type configurations MST
class SelectConfigurationTabs extends React.Component {

    constructor(props) {
        super(props);
        runInAction(() => {
            this.pagedConfigurations = this.pageConfigurations();
            this.availableCategories = Object.keys(this.pagedConfigurations);
            this.selectedCategory = this.availableCategories[0];
        });
    }

    get currentConfigurations() {
        return this.pagedConfigurations[this.selectedCategory] || [];
    }

    get formField() {
        return this.props.formField;
    }

    switchPage(page) {
        this.selectedCategory = page;
    }

    pageConfigurations() {
        const configurations = this.props.configurations || [];
        const configurationsDist = {};
        for(let i = 0; i < configurations.length; i++) {
            const configClassName = configurations[i].name.split(' - ')[0];
            let classAvailable = false;
            // eslint-disable-next-line no-restricted-syntax
            for(const conf in allAvailableCategories) {
                if (configClassName.includes(conf)) {
                    configurationsDist[conf] ??= []
                    configurationsDist[conf].push(configurations[i]);
                    classAvailable = true;
                    break;
                }
            }
            if (!classAvailable) {
                configurationsDist.Other ??= []
                configurationsDist.Other.push(configurations[i]);
            }
        }
        return configurationsDist;
    }

    render() {
        const field = this.formField;
        const { error = '' } = field;
        const hasError = !_.isEmpty(error); // IMPORTANT do NOT use field.hasError
        const isDisabled = field.disabled;
        const disabledClass = isDisabled ? 'disabled' : '';
        const errorClass = hasError ? 'error' : '';

        return (
            <div className={c('mb4', errorClass, disabledClass)}>
                <Header field={field} />
                <Description field={field} />
                {this.renderMenu()}
            </div>
        )
    }

    renderMenu() {
        return (
            <>
            <div className='category-menu'>
                { this.availableCategories.map(categoryName => {
                    const categoryAttrs = allAvailableCategories[categoryName];
                    const thisColor = categoryAttrs ? categoryAttrs.color : "black";
                    const selected = this.selectedCategory === categoryName;
                    return(
                        <div
                            key={categoryName}
                            className={c('item', `color-scheme-${thisColor}`, { selected })}
                            onClick={() => this.switchPage(categoryName)}
                        >{ categoryName }</div>
                    )
                }) }
            </div>
            { 
                allAvailableCategories[this.selectedCategory] && allAvailableCategories[this.selectedCategory].desc &&
                <div>{ allAvailableCategories[this.selectedCategory].desc }</div>
            }
            <SelectConfigurationCards configurations={this.currentConfigurations} formField={this.formField} handleHyperthreadingLock={this.props.handleHyperthreadingLock} /> 
            </>
        )
    }

}

// see https://medium.com/@mweststrate/mobx-4-better-simpler-faster-smaller-c1fbc08008da
decorate(SelectConfigurationTabs, {
    pagedConfigurations: observable,
    selectedCategory: observable,
    availableCategories: observable,
    currentConfigurations: computed,
    formField: computed,
    switchPage: action
});

export default inject()(observer(SelectConfigurationTabs));
