/* eslint-disable no-else-return */
import React from 'react';

function inlineParser(text) {
  const parsedText = text
    // strong
    .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
    // italic
    .replace(/\*(.*?)\*/g, '<em>$1</em>')
    // link
    .replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2">$1</a>')
    // code
    .replace(/`(.*?)`/g, '<code>$1</code>')
    // image
    .replace(/!\[(.*?)\]\((.*?)\)/g, '<img src="$2" alt="$1" />')
    // header
    .replace(/^# (.*)$/gm, '<h1>$1</h1>')
    .replace(/^## (.*)$/gm, '<h2>$1</h2>')
    .replace(/^### (.*)$/gm, '<h3>$1</h3>')
    .replace(/^#### (.*)$/gm, '<h4>$1</h4>')
    .replace(/^##### (.*)$/gm, '<h5>$1</h5>')
    .replace(/^###### (.*)$/gm, '<h6>$1</h6>')
    // blockquote
    .replace(/^> (.*)$/gm, '<blockquote>$1</blockquote>');

  return parsedText;
}

function parser(markdown) {
  const lines = markdown.split('\n');
  let parent = null;
  const listItems = [];
  let html = '';

  lines.forEach(line => {
    if (!line) return;

    if (line.startsWith('```')) {
      if (parent === 'code') {
        parent = null;
        html += '</code></pre>\n';
      } else {
        parent = 'code';
        html += '<pre><code>\n';
      }
      return;
    } else if (parent === 'code') {
      // Add code block content
      html += `${line}\n`;
      return;
    }
    // test if is list
    const match = line.match(/^\s*([-*]|([0-9a-z]+)\.) (.*)/);
    if (match) {
      const [, unorderedList, orderedList, content] = match;

      // eslint-disable-next-line one-var
      let type, tag;
      if (orderedList) {
        type = /^[a-z]+$/.test(orderedList) ? 'a' : '1';
        tag = 'ol';
      } else {
        type = unorderedList.includes('*') ? '*' : '-';
        tag = 'ul';
      }

      if (listItems.length) {
        for (let i = listItems.length - 1, pops = 0; i >= 0; i--, pops++) {
          const lastItem = listItems[i];
          if (lastItem.tag === tag && lastItem.type === type) {
            // same list, continue
            for (let count = 0; count < pops; count++) {
              html += `</${listItems.pop().tag}>`;
            }
            html += `<li>${content}</li>\n`;
            return;
          }
        }
      }
      listItems.push({
        tag, type,
        indent: listItems.length + 1,
      });
      html += `<${tag}${type ? ` type='${type}'` : ''}><li>${content}</li>`;
      return;
    } else {
      // clear listItems
      listItems.reverse();
      while (listItems.length) {
        const { tag } = listItems.pop();
        html += `</${tag}>`;
      }
    }
    // test if is hr
    if (/^---$/.test(line)) {
      html += '</hr>';
      return;
    }
    // add a normal line
    html += `<p>${inlineParser(line)}</p>`;
  });

  return html;
}

export default function MarkDown(props) {
  const { children } = props;
  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: parser(children) }} />;
}
