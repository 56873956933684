/* eslint-disable prettier/prettier */
import React from 'react';
import { withRouter } from 'react-router-dom';

function Documentation() {
    return (
        <iframe src={process.env.REACT_APP_DOCUMENTATION_URL} title='RACE Documentation' className='race-documentation-if' />
    )
}

export default withRouter(Documentation);