/* eslint-disable prettier/prettier */
export function getRootVolumeSizeRange(typeName) {
  switch (typeName) {
    case 'Amazon Linux 2': return { min: 32, max: 1536, default: 32 };
    case 'Amazon Linux 2023': return { min: 32, max: 1536, default: 32 };
    case 'RedHat': return { min: 32, max: 1536, default: 32 };
    case 'Ubuntu': return { min: 32, max: 1536, default: 32 };
    case 'Windows': return { min: 32, max: 1536, default: 32 };
    case 'JupyterLab 1 Notebook': return { min: 8, max: 1536, default: 32 };
    case 'Amazon Linux 2 - ANSYS Fluids and Structures': return { min: 96, max: 2048, default: 128 };
    case 'Amazon Linux 2023 - Machine Learning': return { min: 96, max: 1536, default: 96 };
    case 'Amazon Linux 2023 - Science': return { min: 96, max: 1536, default: 96 };
    case 'RedHat - SIMULIA Abaqus': return { min: 96, max: 2048, default: 128 };
    case 'Ubuntu - ANSYS Electronics': return { min: 96, max: 2048, default: 96 };
    case 'Ubuntu - ANSYS Lumerical': return { min: 96, max: 1536, default: 96 };
    case 'Ubuntu - Chemistry': return { min: 96, max: 2048, default: 96 };
    case 'Ubuntu - Development': return { min: 96, max: 1536, default: 96 };
    case 'Ubuntu - Machine Learning': return { min: 96, max: 1536, default: 96 };
    case 'Windows - ANSYS Electronics': return { min: 96, max: 2048, default: 96 };
    case 'Windows - ANSYS Engineering': return { min: 128, max: 2048, default: 256 };
    case 'Windows - ANSYS Fluids and Structures': return { min: 128, max: 2048, default: 256 };
    case 'Windows - ANSYS Lumerical': return { min: 96, max: 1536, default: 96 };
    case 'Windows - COMSOL Multiphysics': return { min: 96, max: 1536, default: 96 };
    case 'Windows - Chemistry': return { min: 128, max: 2048, default: 128 };
    case 'Windows - Lumivero NVivo': return { min: 96, max: 1024, default: 96 };
    case 'Windows - Machine Learning': return { min: 96, max: 1536, default: 96 };
    case 'Windows - Science': return { min: 128, max: 1536, default: 128 };
    default: return { min: 32, max: 1536, default: 32 };
  }
}

export const allAvailableCategories = {
  'Basic': { color: 'red', desc: 'Based on AWS burstable technology, these workspaces provide you with hardware designed for applications with moderate to low CPU usage that experience temporary spikes in use.' },
  'General': { color: 'blue', desc: 'These workspaces are balanced for compute and memory, and are designed for computations. They have a CPU to memory (RAM) ratio of 1CPU:8GB.' },
  'Compute Optimised': { color: 'orange', desc: 'These workspaces are designed for compute-intensive workloads. They have a CPU to memory (RAM) ratio of 1CPU:4GB.' },
  'High Memory': { color: 'green', desc: 'These workspaces are designed for memory-intensive workloads. They have a CPU to memory (RAM) ratio of 1CPU:16GB.' },
  'T4': { color: 'purple', desc: 'These workspaces are powered by NVIDIA T4 GPUs.' },
  'A10G': { color: 'brown', desc: 'These workspaces are powered by NVIDIA A10G GPUs.' },
  'V100': { color: 'olive', desc: 'These workspaces are powered by NVIDIA V100 GPUs.' },
};
