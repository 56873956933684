import React from 'react';
import { Image } from 'semantic-ui-react';
import amazonlinux from '../../../../images/blank-image-icons/amazonlinux.png';
import redhat from '../../../../images/blank-image-icons/redhat.png';
import jupyter from '../../../../images/blank-image-icons/jupyter.png';
import ubuntu from '../../../../images/blank-image-icons/ubuntu.png';
import windows from '../../../../images/blank-image-icons/windows.png';

const imgsList = {
  amazonlinux,
  redhat,
  jupyter,
  ubuntu,
  windows,
};

function BlankImageIcons(props) {
  const name = props.name
    .toLowerCase()
    .split(' ')
    .join('');
  const imgSrc = imgsList[Object.keys(imgsList).find(e => name.includes(e))];

  return <Image src={imgSrc} alt={name} />;
}

export default BlankImageIcons;
