/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */
import _ from 'lodash';
import React from 'react';
import { decorate, computed, runInAction, observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Icon, Header, Segment, Button, Card, Radio, Divider, GridRow, GridColumn, Grid, Table, TableHeader, TableHeaderCell, TableBody, TableCell, TableRow, Loader, Dimmer, Image } from 'semantic-ui-react';
import c from 'classnames';
import { displayError } from '@amzn/base-ui/dist/helpers/notification';
import { swallowError, mapToArray } from '@amzn/base-ui/dist/helpers/utils';
import { isStoreLoading, isStoreError, isStoreEmpty } from '@amzn/base-ui/dist/models/BaseStore';
import BasicProgressPlaceholder from '@amzn/base-ui/dist/parts/helpers/BasicProgressPlaceholder';
import { gotoFn } from '@amzn/base-ui/dist/helpers/routing';
import ErrorBox from '@amzn/base-ui/dist/parts/helpers/ErrorBox';

import Logos from './Logos';
import BlankImageIcons from './BlankImageIcons';

// expected props
// - onPrevious (via props)
// - onNext (via props) a function is called with the selected envTypeId
// - envTypesStore (via injection)
// - userStore (via injection)
class SelectEnvTypeStep extends React.Component {
  constructor(props) {
    super(props);
    runInAction(() => {
      this.selectedEnvTypeId = undefined;
      this.processing = false;

      const envTypes = this.envTypesStore.listApproved || []; // Get all approved workspace types
      const nonDeprecatedArray = _.filter(envTypes, e => !e.name.includes('DEPRECATED')); // Filter out deprecated workspace types
      const sortedEnvTypes = _.sortBy(mapToArray(nonDeprecatedArray), e => e.name); // Sort workspace types alphabetically
      const groupedEnvTypes = _.groupBy(sortedEnvTypes, 'category'); // Group environment types by category

      // take blank images out
      // eslint-disable-next-line prefer-const
      let { 'Blank Images': blankImages, ...CustomImages } = groupedEnvTypes;
      // disgard GPU images
      // blankImages = blankImages.filter(e => !e.name.includes('GPU'));
      // sort images in place
      const sortImgs = ['windows', 'ubuntu', 'amazon', 'redhat', 'jupyter'];

      if (blankImages) {
        blankImages.sort((a, b) => {
          const aIndex = sortImgs.findIndex(e => a.name.toLowerCase().includes(e));
          const bIndex = sortImgs.findIndex(e => b.name.toLowerCase().includes(e));
          return aIndex - bIndex;
        });
      }

      const sortedCategories = _.sortBy(Object.keys(CustomImages), category => {
        if (category === '') {
          return String.fromCharCode(-1);
        }
        if (category === 'Other') {
          return String.fromCharCode(-2);
        }
        return category.toLowerCase();
      });
      this.groupedEnvTypes = CustomImages;
      this.blankImages = blankImages;
      this.sortedCategories = sortedCategories;
      this.selectedCategory = sortedCategories.length ? sortedCategories[0] : null;
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    swallowError(this.envTypesStore.load());
  }

  goto(pathname) {
    const goto = gotoFn(this);
    goto(pathname);
  }

  get userStore() {
    return this.props.userStore;
  }

  get envTypesStore() {
    return this.props.envTypesStore;
  }

  get displayingTypes() {
    return this.groupedEnvTypes[this.selectedCategory];
  }

  selectCategory(categoryName) {
    runInAction(() => {
      this.selectedCategory = categoryName;
    });
  }

  handleSelectedEnvType = typeId => {
    this.selectedEnvTypeId = typeId;
  };

  handlePrevious = event => {
    event.preventDefault();
    event.stopPropagation();
    if (_.isFunction(this.props.onPrevious)) this.props.onPrevious();
  };

  async processNext(typeId = null) {
    if (_.isFunction(this.props.onNext)) {
      try {
        this.processing = true;
        await this.props.onNext(typeId || this.selectedEnvTypeId);
      } catch (error) {
        displayError(error);
      } finally {
        runInAction(() => {
          this.processing = false;
        });
      }
    }
  }

  handleNext = async event => {
    event.preventDefault();
    event.stopPropagation();
    this.processNext();
  };

  render() {
    const store = this.envTypesStore;
    if (!store) return null;

    let content;
    if (isStoreError(store)) {
      content = this.renderLoadingError();
    } else if (isStoreLoading(store)) {
      content = <BasicProgressPlaceholder className="mt2" />;
    } else if (isStoreEmpty(store)) {
      content = this.renderEmpty();
    } else {
      content = this.renderContent();
    }

    return content;
  }

  renderContent() {
    // Logic:
    // - if external researcher and no aws creds configured yet, show a message
    // - if guest (internal/external) show a message
    // - if internal researcher and no project ids, show a message
    // - else show env types card
    const nextDisabled = _.isUndefined(this.selectedEnvTypeId);

    return (
      <div className="mt2 animated fadeIn">
        <Dimmer active={this.processing} inverted>
          <Loader />
        </Dimmer>
        <Header as="h3" className="mt4 mb2">
          Blank Images
        </Header>
        {this.renderBlankImageCards()}
        <Header as="h3" className="mt4 mb2">
          Custom Images
        </Header>
        {this.renderTable()}
        {this.renderButtons({ nextDisabled })}
      </div>
    );
  }

  renderCards() {
    const processing = this.processing;
    const envTypes = this.envTypesStore.listApproved || []; // Get all approved workspace types
    const nonDeprecatedArray = _.filter(envTypes, e => !e.name.includes('DEPRECATED')); // Filter out deprecated workspace types
    const sortedEnvTypes = _.sortBy(mapToArray(nonDeprecatedArray), e => e.name); // Sort workspace types alphabetically
    const groupedEnvTypes = _.groupBy(sortedEnvTypes, 'category'); // Group environment types by category

    const sortedCategories = _.sortBy(Object.keys(groupedEnvTypes), category => {
      if (category === '') {
        return String.fromCharCode(-1);
      }
      if (category === 'Other') {
        return String.fromCharCode(-2);
      }
      return category.toLowerCase();
    });

    const isSelected = type => type.id === this.selectedEnvTypeId;
    const getAttrs = type => {
      const attrs = {};
      if (isSelected(type)) attrs.color = 'blue';
      if (!processing) attrs.onClick = () => this.handleSelectedEnvType(type.id);
      return attrs;
    };

    return (
      <>
        {/* Render environment types with categories */}
        {sortedCategories.map(category => (
          <React.Fragment key={category}>
            {category && (
              <Header as="h3" className="mt4 mb2">
                {category.trim()}
              </Header>
            )}
            <Card.Group stackable itemsPerRow={3}>
              {groupedEnvTypes[category].map(type => (
                <Card
                  data-testid="env-type-card"
                  key={type.id}
                  raised
                  className={c('mb3', { 'cursor-pointer': !processing })}
                  {...getAttrs(type)}
                >
                  <Card.Content>
                    <Card.Header>
                      <div className="flex mt1">
                        <Radio className="mr2" checked={isSelected(type)} disabled={processing} />
                        <Header as="h4" className="flex-auto mt0 pt0">
                          {type.name}
                        </Header>
                        <Logos.Dynamic name={type.name} size="large" className="ml1" />
                      </div>
                      <Divider />
                    </Card.Header>
                    <Card.Description>
                      <div className="mb3 pr1 pl1 pb1">
                        {/* Yes, we are doing dangerouslySetInnerHTML, the content was already sanitized by showdownjs */}
                        {/* eslint-disable-next-line react/no-danger */}
                        <div dangerouslySetInnerHTML={{ __html: type.descHtml }} />
                      </div>
                    </Card.Description>
                  </Card.Content>
                </Card>
              ))}
            </Card.Group>
          </React.Fragment>
        ))}
      </>
    );
  }

  renderBlankImageCards() {
    return (
      <Grid
        divided
        className="blank-images-select-grid"
        columns={this.blankImages ? Math.min(this.blankImages.length, 5) : 5}
      >
        {_.chunk(this.blankImages, 5).map((row, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <GridRow key={i}>
            {_.map(row, type => (
              <GridColumn key={type.id} onClick={() => this.processNext(type.id)}>
                <BlankImageIcons name={type.name} />
                {type.name}
              </GridColumn>
            ))}
          </GridRow>
        ))}
      </Grid>
    );
  }

  renderTable() {
    return (
      <>
        <Grid columns={this.sortedCategories.length} divided className="env-type-category-grid">
          <GridRow>
            {_.map(this.sortedCategories, category => (
              <GridColumn
                onClick={() => this.selectCategory(category)}
                className={c({ selected: this.selectedCategory === category })}
              >
                <Logos.Dynamic name={category} size="large" className="mr1" />
                <span>{category}</span>
              </GridColumn>
            ))}
          </GridRow>
        </Grid>
        <Table celled className="selectable">
          <TableHeader colSpan="3">
            <TableHeaderCell className="select-env-table-radio" />
            <TableHeaderCell className="select-env-table-name">Name</TableHeaderCell>
            <TableHeaderCell>Description</TableHeaderCell>
          </TableHeader>
          <TableBody>
            {_.map(this.displayingTypes, type => {
              return (
                <TableRow key={type.id} onClick={() => this.handleSelectedEnvType(type.id)}>
                  <TableCell className="select-env-table-radio">
                    <Radio checked={this.selectedEnvTypeId === type.id} />
                  </TableCell>
                  <TableCell className="select-env-table-name">{type.name}</TableCell>
                  <TableCell>
                    {/* eslint-disable-next-line react/no-danger */}
                    <div dangerouslySetInnerHTML={{ __html: type.descHtml }} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </>
    );
  }

  renderLoadingError() {
    const store = this.envTypesStore;
    return (
      <>
        <ErrorBox error={store.error} className="p0 mt2 mb3" />
        {this.renderButtons()}
      </>
    );
  }

  renderEmpty() {
    return (
      <>
        <Segment placeholder className="mt2">
          <Header icon className="color-grey">
            <Icon name="server" />
            No workspace types
            <Header.Subheader>
              There are no workspace types to choose from. Your role might be restricted. Please contact your
              administrator.
            </Header.Subheader>
          </Header>
        </Segment>
        {this.renderButtons()}
      </>
    );
  }

  renderButtons({ nextDisabled = true } = {}) {
    const processing = this.processing;
    return (
      <div className="mt3">
        <Button
          floated="right"
          icon="right arrow"
          labelPosition="right"
          className="ml2"
          primary
          content="Next"
          loading={processing}
          disabled={nextDisabled || processing}
          onClick={this.handleNext}
        />
        <Button
          floated="right"
          icon="left arrow"
          labelPosition="left"
          className="ml2"
          content="Previous"
          disabled={processing}
          onClick={this.handlePrevious}
        />
      </div>
    );
  }
}

// see https://medium.com/@mweststrate/mobx-4-better-simpler-faster-smaller-c1fbc08008da
decorate(SelectEnvTypeStep, {
  handlePrevious: action,
  handleNext: action,
  handleSelectedEnvType: action,
  processNext: action,
  groupedEnvTypes: observable,
  blankImages: observable,
  sortedCategories: observable,
  selectedCategory: observable,
  displayingTypes: computed,
  userStore: computed,
  envTypesStore: computed,
  processing: observable,
  selectedEnvTypeId: observable,
});

export default inject('userStore', 'envTypesStore')(withRouter(observer(SelectEnvTypeStep)));
