/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */
import _ from 'lodash';
import React from 'react';
import { decorate, computed, runInAction, observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Segment, Button, Header, Icon, Message } from 'semantic-ui-react';
import { displayError } from '@amzn/base-ui/dist/helpers/notification';
import Dropdown from '@amzn/base-ui/dist/parts/helpers/fields/DropDown';
import Form from '@amzn/base-ui/dist/parts/helpers/fields/Form';
import Input from '@amzn/base-ui/dist/parts/helpers/fields/Input';
import SelectionButtons from '@amzn/base-ui/dist/parts/helpers/fields/SelectionButtons';
import TextArea from '@amzn/base-ui/dist/parts/helpers/fields/TextArea';
import Range from '@amzn/base-ui/dist/parts/helpers/fields/Range';

import { getCreateInternalEnvForm } from '../../../models/forms/CreateInternalEnvForm';
import SelectConfigurationTabs from './SelectConfigurationTabs';
import { getRootVolumeSizeRange } from './EnvTypeAdditionalInfo';

// expected props
// - onPrevious (via props)
// - onNext (via props) a function is called with the form data
// - envTypeId (via props)
// - configurations (via props)
// - title (via props)
// - defaultCidr (via props)
// - clientInformationStore (via injection)
// - userStore (via injection)
// - projectsStore (via injection)
class CreateInternalEnvForm extends React.Component {
  constructor(props) {
    super(props);
    runInAction(() => {
      const rootVolumeSizeRange = getRootVolumeSizeRange(this.props.title);
      const projectIdOptions = this.getProjectIdOptions();
      this.form = getCreateInternalEnvForm({
        projectIdOptions,
        cidr: this.isAppStreamEnabled ? undefined : this.props.defaultCidr,
        rootVolumeSizeRange,
      });

      this.forceLockHyperthreading = false;
      if (this.props.title.toLowerCase().includes('jupyterlab')) {
        this.forceLockHyperthreading = true;
        this.form.$('enableHyperthreading').set(false);
      }
      this.hyperthreadingLocked = this.forceLockHyperthreading;
      this.rootVolumeSizeRange = rootVolumeSizeRange;
    });
  }

  // The list of projects assigned to the user might be broader than the
  // list of projects actually available for environment provisioning
  // For example: Projects not fully configured with AppStream need to be filtered out
  getProjectIdOptions() {
    const store = this.userStore;
    if (!this.isAppStreamEnabled) return store.projectIdDropdown;

    const projects = this.getProjects();
    const filteredProjects = _.filter(projects, proj => proj.isAppStreamConfigured);
    if (_.isEmpty(filteredProjects)) return [];

    const filteredProjectIds = _.map(filteredProjects, proj => proj.id);
    const retVal = _.filter(store.projectIdDropdown, proj => _.includes(filteredProjectIds, proj.key));

    return retVal;
  }

  get isAppStreamEnabled() {
    return process.env.REACT_APP_IS_APP_STREAM_ENABLED === 'true';
  }

  get envTypeId() {
    return this.props.envTypeId;
  }

  get configurations() {
    return this.props.configurations;
  }

  get userStore() {
    return this.props.userStore;
  }

  getProjects() {
    const store = this.getProjectsStore();
    return store.list;
  }

  getProjectsStore() {
    const store = this.props.projectsStore;
    store.load();
    return store;
  }

  // eslint-disable-next-line consistent-return
  handlePrevious = () => {
    if (_.isFunction(this.props.onPrevious)) return this.props.onPrevious();
  };

  // eslint-disable-next-line consistent-return
  handleNext = async form => {
    const data = { ...form.values(), envTypeId: this.envTypeId };

    try {
      await this.props.onNext(data);
    } catch (error) {
      displayError(error);
    }
  };

  handleVolumeChange = (event, { value }) => {
    const volume = Number(value);
    this.form.$('rootVolume').set(volume);
  };

  handleHyperthreadingChange = (event, data) => {
    const { value } = data;
    this.form.$('enableHyperthreading').set(value);
  };

  handleHyperthreadingLock = (lock, available = true) => {
    if (this.forceLockHyperthreading) return;
    if (lock) {
      this.handleHyperthreadingChange(null, { value: available });
      this.hyperthreadingLocked = true;
    } else {
      this.hyperthreadingLocked = false;
    }
  };

  render() {
    const title = this.props.title || '';
    return (
      <div className="mt2">
        <Header as="h3" textAlign="center">
          {title}
        </Header>
        {this.renderForm()}
      </div>
    );
  }

  renderButtons() {
    return (
      <div className="mt3">
        <Button
          floated="right"
          icon="right arrow"
          labelPosition="right"
          className="ml2"
          primary
          content="Next"
          disabled
        />
        <Button
          floated="right"
          icon="left arrow"
          labelPosition="left"
          className="ml2"
          content="Previous"
          onClick={this.handlePrevious}
        />
      </div>
    );
  }

  renderMissingAppStreamConfig() {
    return (
      <>
        <Segment placeholder className="mt2">
          <Header icon className="color-grey">
            <Icon name="lock" />
            Missing association with AppStream projects
            <Header.Subheader>
              Your projects are not associated to an AppStream-configured account. Please contact your administrator.
            </Header.Subheader>
          </Header>
        </Segment>
        {this.renderButtons()}
      </>
    );
  }

  renderForm() {
    const form = this.form;
    const shouldAskforCidr = !_.isUndefined(this.props.defaultCidr) && !this.isAppStreamEnabled;
    const configurations = this.configurations;
    const cidrField = shouldAskforCidr ? form.$('cidr') : undefined;
    const volumeField = form.$('rootVolume');
    const rootVolumeCost = volumeField.value * 0.096;
    // we show the AppStream configuration warning when the feature is enabled,
    // and the user's projects are not linked to AppStream-configured accounts
    const projects = this.getProjectIdOptions();
    if (this.isAppStreamEnabled && _.isEmpty(projects)) {
      return this.renderMissingAppStreamConfig();
    }

    const hyperthreadingOptions = [
      { text: 'Enabled', value: true },
      { text: 'Disabled', value: false },
    ];

    return (
      <Segment clearing className="p3 mb3">
        <Form form={form} onCancel={this.handlePrevious} onSuccess={this.handleNext}>
          {({ processing, onCancel }) => (
            <>
              <div className="new-ws-form-grid">
                <Input dataTestId="workspace-name" field={form.$('name')} className="elem" showErrorLabel={false} />

                <Range {...this.rootVolumeSizeRange} dataTestId="root-volume" field={form.$('rootVolume')} onChange={this.handleVolumeChange} className="elem" showErrorLabel={false} />

                <TextArea dataTestId="description-text-area" field={form.$('description')} className="elem" showErrorLabel={false} />

                {shouldAskforCidr && <Input field={cidrField} dataTestId="cidr" className="elem" showErrorLabel={false} />}

                <Dropdown dataTestId="project-id" field={form.$('projectId')} fluid selection className="elem" showErrorLabel={false} />

                <SelectionButtons
                  field={form.$('enableHyperthreading')}
                  options={hyperthreadingOptions}
                  alt="true"
                  className="elem"
                  disabled={this.hyperthreadingLocked}
                  onChange={this.handleHyperthreadingChange}
                />
              </div>
              {volumeField.value >= this.rootVolumeSizeRange.max * 0.75 && (
                <>
                  <Message
                    className="mt1"
                    icon="warning"
                    header="Do you require more storage space?"
                    content="If you require a larger volume size, please contact race@rmit.edu.au!"
                  />
                  <div />
                </>
              )}
              {shouldAskforCidr && !_.isEmpty(cidrField.value) && cidrField.value.split('/')[1] < 24 && (
                <Message
                  className="mt1"
                  icon="warning"
                  header="Wide CIDR block detected"
                  content="One or more CIDR blocks entered might be too wide and could allow workspace access to a vast number of IP ranges. 
                  Please proceed only after you ensure this is intended."
                />
              )}
              {shouldAskforCidr &&
                !_.isEmpty(cidrField.value) &&
                (cidrField.value.split('/')[1] < 16 || cidrField.value.toString().includes('0.0.0.0')) && (
                  <Message
                    negative
                    className="mt1"
                    icon="warning"
                    header="Open CIDR block detected"
                    content="One of the CIDR blocks entered is vulnerable as it makes the workspace accessible to anyone."
                  />
                )}
              {/* <SelectConfigurationCards configurations={configurations} formField={form.$('envTypeConfigId')} /> */}
              <SelectConfigurationTabs
                configurations={configurations}
                formField={form.$('envTypeConfigId')}
                handleHyperthreadingLock={this.handleHyperthreadingLock}
              />
              <Button
                floated="right"
                className="ml2 go-next"
                icon="right arrow"
                labelPosition="right"
                primary
                content="Create Workspace"
                disabled={processing}
                type="submit"
              />
              <Button
                floated="right"
                icon="left arrow"
                labelPosition="left"
                className="ml2 go-back"
                content="Previous Page"
                disabled={processing}
                onClick={onCancel}
              />
            </>
          )}
        </Form>
      </Segment>
    );
  }
}

// see https://medium.com/@mweststrate/mobx-4-better-simpler-faster-smaller-c1fbc08008da
decorate(CreateInternalEnvForm, {
  form: observable,
  envTypeId: computed,
  configurations: computed,
  userStore: computed,
  isAppStreamEnabled: computed,
  handlePrevious: action,
  handleHyperthreadingLock: action,
  hyperthreadingLocked: observable,
  forceLockHyperthreading: observable,
  rootVolumeSizeRange: observable,
});

export default inject('userStore', 'projectsStore', 'clientInformationStore')(observer(CreateInternalEnvForm));
